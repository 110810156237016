<template>
  <CRow>
    <CCol col="12" lg="6">
      <CCard no-header>
        <CCardBody>
          <CForm>
            <template slot="header"> Edit Account id: {{ $route.params.id }} </template>
            <CAlert :show.sync="dismissCountDown" color="primary" fade> ({{ dismissCountDown }}) {{ message }} </CAlert>
            <CInput type="text" label="Name" placeholder="Name" v-model="name"></CInput>
            <CInput type="text" label="Description" placeholder="Description" v-model="description"></CInput>
            <CButton class="mr-2" color="primary" @click="update()">Save</CButton>
            <CButton color="secondary" @click="goBack">Back</CButton>
          </CForm>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'EditAccount',
  props: {
    caption: {
      type: String,
      default: 'Account id'
    }
  },
  data() {
    return {
      name: '',
      description: '',
      showMessage: false,
      message: '',
      dismissSecs: 7,
      dismissCountDown: 0,
      showDismissibleAlert: false
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
      // this.$router.replace({path: '/accounts'})
    },
    update() {
      let self = this
      const account = { id: this.$route.params.id, name: this.name, description: this.description }
      this.$http
        .put('admin/accounts', account)
        .then(() => {
          self.message = 'Successfully updated account.'
          self.showAlert()
        })
        .catch(error => {
          console.error(error)
          self.$router.push({ path: '/login' })
        })
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    }
  },
  mounted() {
    let self = this
    this.$http
      .get(`admin/accounts/${this.$route.params.id}/edit`)
      .then(response => {
        self.name = response.data.account.name
        self.description = response.data.account.description
      })
      .catch(error => {
        console.error(error)
        self.$router.push({ path: '/login' })
      })
  }
}
</script>
